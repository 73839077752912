<template>
	<section class="container">
		<div class="row justify-content-center">
			<b-form @submit.prevent="onSubmit" class="col-6">
				<companyInfo v-model="company.companyInfo" :isNew="true"> </companyInfo>
				<div class="col-2 p-0">
					<BaseActionButton class="btn-add" v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton">
						{{ $t('global.create') }}
					</BaseActionButton>
				</div>
			</b-form>
		</div>
	</section>
</template>
<script>
import { Company as CompanyModel } from '@/models/company'
import CompanyInfo from '@/modules/general/company/components/CompanyInfo'
export default {
	name: 'CompanyAdd',
	components: {
		companyInfo: CompanyInfo,
	},
	async mounted() {
		this.$emit('updateLoader')
	},
	data() {
		return {
			disabled: false,
			company: new CompanyModel({}),
			clicked: false,
		}
	},
	computed: {},
	methods: {
		async onSubmit() {
			this.clicked = true
			try {
				var company = await this.$store.dispatch('companyVuex/addCompany', this.company)
				this.clicked = false
				this.toast('Success', 'Et selskab nu oprettet', true)
				this.$router.push({ path: company.id + '/info' }) // Her er den originial fix når backend er rdy
			} catch (error) {
				this.clicked = false
				console.log(error)
				this.toast('Fejl', 'En fejl er sket:' + '(' + error.message + ')')
			}
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
